.navbar-brand img {
    height: 40px;
    width: auto;
    max-width: 100%;


}

.bg-body-tertiary {
    background-color: white !important;
    /* box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .4); */
    padding: 0;
}

.offcanvas-body .nav-link {
    text-align: center;
    margin: 0px 15px;
    font-size: 16px;
    font-weight: 600;
}

.offcanvas-body .dropdown-item {
    font-size: 14px;
    font-weight: 600;
}

.offcanvas-body .nav-link span {
    font-size: 10px;
    font-weight: 600;
}

.offcanvas-body button {
    height: 30px;
    font-size: 14px;
    line-height: 15px;
    font-weight: 600;
}

.offcanvas-body img {
    height: 28px;
}

.login-btn {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.history-btn {
    margin-right: 10px;
}

.navbar-toggler:focus {
    box-shadow: none;
}

@media (max-width:991px) {
    .offcanvas-body .nav-link {
        font-size: 24px;
    }

    .dropdown-menu {
        border: none;
    }

    .offcanvas-body .dropdown-item {
        font-size: 18px;
    }

    .offcanvas-body {
        justify-content: center;
        align-items: center;
        display: grid;
        height: 100vh;
    }
}