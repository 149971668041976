/* footer {
    position: fixed;
    left: 0;
    bottom: 0px;
    height: auto;
    width: 100%;
    margin-top: 100px;

} */

footer a {
    font-size: 12px;
}

.footer,
.footer a {
    background-color: white;
    color: black
}